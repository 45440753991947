import React from 'react';
import { Reveal, Fade } from 'react-reveal';
import userGraduate from '../../../assets/images/user-graduate-solid.svg';
import holdingUsd from '../../../assets/images/hand-holding-usd-solid.svg';
import chalkboard from '../../../assets/images/chalkboard-teacher-solid.svg';
import { FaFileContract } from "react-icons/fa";

import userTie from '../../../assets/images/user-tie-solid.svg';
import chartLine from '../../../assets/images/chart-line-solid.svg';
import calendar from '../../../assets/images/calendar-solid.svg';
import './style.css';


const ServicesArea = () => (
	<section id="servicesArea" className="services-area sec-padding bg-gray text-center" data-scroll-index={4}>
	  <Reveal effect="fadeInUp" duration={2000}>
			<div className="container">
				<h1 className="title-h">أقسام النظام</h1>
				{/* <p className="title-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
				<div className="row justify-content-md-center">
					<Fade bottom duration={600}>
						<div className="col-md-4">
							<div className="services-text mt-25px mb-25px bg-fff bg-blue-hvr transition-3 radius-10px wow fadeInUp" data-wow-delay="0.3s">
								{/* <i className="im im-graduation-hat color-blue fs-50 mb-25px" /> */}
								<img src={userGraduate} />
								<h4 className="mb-10px">القبول والتسجيل</h4>
								<p>عبارة عن وحدة متكاملة لتسجيل الطلاب وتصنيفهم داخل النظام</p>
							</div>
						</div>
					</Fade>
					<Fade bottom duration={800}>
						<div className="col-md-4">
							<div className="services-text mt-25px mb-25px bg-fff bg-blue-hvr transition-3 radius-10px wow fadeInUp" data-wow-delay="0.5s">
								{/* <i className="im im-sun color-blue fs-50 mb-25px" /> */}
								<img src={holdingUsd} />
								<h4 className="mb-10px">الرسوم الدراسية</h4>
								<p>وحدة تدير الرسوم الدراسية للطالب بحيث تجدول عدد الأقساط والمبالغ المطلوبة والمدفوعة وتقوم بارسال تنبيه لإدارة الحضانة وأولياء الأمور بموعد سداد القسط وتعرض تقارير تفصيلية عن الرسوم في الحضانة</p>
							</div>
						</div>
					</Fade>
					<Fade bottom duration={1000}>
						<div className="col-md-4">
							<div className="services-text mt-25px mb-25px bg-fff bg-blue-hvr transition-3 radius-10px wow fadeInUp" data-wow-delay="0.7s">
								{/* <i className="im im-wrench color-blue fs-50 mb-25px" /> */}
								<img src={userTie} />
								<h4 className="mb-10px">المدرسين</h4>
								<p>
								عبارة عن وحدة تسجيل لأهم البيانات الشخصية التي تخص المدرسين العاملين في الحضانة.
								</p>
							</div>
						</div>
					</Fade>
					<Fade bottom duration={1000}>
						<div className="col-md-4">
							<div className="services-text mt-25px mb-25px bg-fff bg-blue-hvr transition-3 radius-10px wow fadeInUp" data-wow-delay="0.7s">
								{/* <i className="im im-wrench color-blue fs-50 mb-25px" /> */}
								<img src={chartLine} />
								<h4 className="mb-10px">التقارير</h4>
								<p>عبارة عن وحدة تقدم تقارير شاملة وتفصيلية بطريقة سهلة ومرنة</p>
							</div>
						</div>
					</Fade>
					<Fade bottom duration={1000}>
						<div className="col-md-4">
							<div className="services-text mt-25px mb-25px bg-fff bg-blue-hvr transition-3 radius-10px wow fadeInUp" data-wow-delay="0.7s">
								{/* <i className="im im-wrench color-blue fs-50 mb-25px" /> */}
								<img src={chartLine} />
								<h4 className="mb-10px">الاحصائيات</h4>
								<p>
								صفحة تعرض ملخصات عن كل المعلومات الموجودة  في النظام على شكل رسوم بيانية ومعلومات إحصائية بتصميم سهل الفهم وجذاب
								</p>
							</div>
						</div>
					</Fade>
					<Fade bottom duration={1000}>
						<div className="col-md-4">
							<div className="services-text mt-25px mb-25px bg-fff bg-blue-hvr transition-3 radius-10px wow fadeInUp" data-wow-delay="0.7s">
								{/* <i className="im im-wrench color-blue fs-50 mb-25px" /> */}
								<img src={calendar} />
								<h4 className="mb-10px">جدول الحصص</h4>
								<p>
								عبارة عن وحدة تتيح للحضانة انشاء جداول الحصص و ارسالها إلى أولياء الأمور بسهولة عبر تطبيق قلم.
									</p>
							</div>
						</div>
					</Fade>
					<Fade bottom duration={1000}>
						<div className="col-md-4">
							<div className="services-text mt-25px mb-25px bg-fff bg-blue-hvr transition-3 radius-10px wow fadeInUp" data-wow-delay="0.7s">
								{/* <i className="im im-wrench color-blue fs-50 mb-25px" /> */}
								{/* <img src={calendar} /> */}
								<FaFileContract />
								<h4 className="mb-10px">النماذج الإدارية</h4>
								<p>
								عبارة عن وحدة متكاملة لأغلب النماذج الإدارية المستخدمة في الحضانات 
								</p>
							</div>
						</div>
					</Fade>
				</div>
			</div>
		</Reveal>
	</section>
);

export default ServicesArea;
