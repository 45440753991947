import React from 'react';
import { Fade } from 'react-reveal';
import { Link } from 'gatsby';
import './style.css';


const WelcomeArea = () => (
  <section className="welcome-area sec-padding p-relative o-hidden bg-gray">
    <div className="container">
      <div className="row welcome-text sec-padding flex-center">
          <div className="col-md-6 mb-50px z-index-1 text-right">
        <Fade bottom>
            <h1 className="mb-20px">قلم الحل المثالي لإدارة الحضانات الذكية</h1>
            <p>
            نظام قلم لإدارة الحضانات يساعدك على تحقيق التكامل والبساطة والتواصل الأسرع مع أولياء الأمور .
نظام قلم أسهل نظام لإتمام جميع مهام التسجيل ودفع الرسوم الدراسية.
            </p>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <a href="https://apps.apple.com/in/app/galam/id1637061929" target="_blank">
                <img style={{ height: 40 }} src="/images/apple-store-badge.svg" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=inc.galam.app" target="_blank">
                <img style={{ height: 55 }} src="/images/google-play-badge.png" />
              </a>
            </div>
        </Fade>
            <Link to="get_started" className="main-btn btn-3 mt-40px ml-10px before-gray">أطلب نسختك التجريبية الآن</Link>
            {/* <button className="main-btn btn-3 btn-orange mt-10px before-gray"><i className="fa fa-play" /> Watch Our Video</button> */}
          </div>
        <div className="col-md-6 text-center">
          <img alt="img" src="/images/ll.png" />
        </div>
      </div>
    </div>
    <div className="pattern p-absolute">
    </div>
  </section>
);

export default WelcomeArea;
