import React from 'react';
import { Reveal, Fade } from 'react-reveal';
import './style.css';


const VisionArea = () => (
	<section id="visionArea" className="vision-area sec-padding">
	  <Reveal effect="fadeInUp" duration={2000}>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="flex">
							<div className="vision-dots">
								<Fade bottom>
									<div className="dot mb-40px">
										<i className="im im-color-fan fs-20 bg-gray radius-50 text-center mb-10px mr-5px" /> 
										<h4 className="mb-5px">بسيطة وسهلة الاستخدام</h4>
										{/* <p>بسيطة وسهلة الاستخدام</p> */}
									</div>						
								</Fade>
								<Fade bottom>
									<div className="dot mb-40px">
										<i className="im im-idea fs-20 bg-gray radius-50 text-center mb-10px mr-5px" /> 
										<h4 className="mb-5px">التخزين السحابي</h4>
										<p>
										تخزين سحابي بحيث يسهل الوصول إليه في أي وقت ومن أي مكان
										</p>
									</div>
								</Fade>
								<Fade bottom>
									<div className="dot">
										<i className="im im-laptop-o fs-20 bg-gray radius-50 text-center mb-10px mr-5px" /> 
										<h4 className="mb-5px">التقارير</h4>
										<p>إعداد تقارير تفصيلية وشاملة وذكية تساعدك في اتخاذ القرار.</p>
									</div>
								</Fade>
							</div>
						<div className="mt-25px">
							<img style={{ maxWidth: 590, width: '100%' }} alt="img" src="/images/kids.jpeg" />
						</div>
						</div>
					</div>
				</div>  
			</div>
		</Reveal>
	</section>
);

export default VisionArea;
