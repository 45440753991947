import React from 'react';
import { Reveal } from 'react-reveal';

import './style.css';


const AboutArea = () => (
<section id="aboutArea" className="about-area sec-padding text-center">
  <Reveal effect="fadeInUp" duration={1000}>
    <div className="">
      <div className="">
        <div className="m-auto" style={{ maxWidth: 800 }}>
          <h1 className="title-h bg-gray d-inline-block radius-50px pt-5px pb-5px pl-25px pr-25px color-blue mb-30px wow fadeInUp" data-wow-delay="0.45s">من نحن</h1>
          <p className="wow fadeInUp" data-wow-delay="0.6s" style={{ fontSize: 16 }}>
          إيماناً منا بأهمية تطوير التعليم والنظم الإدارية تقدم مؤسسة آي ثينكر للإستشارات التربوية كل ما هو جديد لاستخدام التكنولوجيا في التعليم وتسهيل التواصل بين إدارة الحضانة و ولي الأمر.
          <br />
 وجود موقع خاص لإدارة الحضانات الذكية على الإنترنت يعتبر من أهم مؤشرات نجاح عملية إدارة حضانتكم  والاهتمام بجميع جوانبها.
          <br />
          مؤسسة آي ثينكر للإستشارات التربوية تقدم لكم تطبيق قلم لإدارة الحضانات نظام متكامل لتنظيم إدارة الحضانة،  
          <br />
 حيث تم بناء عدة أدوات مناسبة لتحسين التواصل بين أطراف الحضانة بسهولة.
          </p>
          <img alt="img" src="/images/b.png" className="wow fadeInUp" data-wow-delay="0.85s" />
        </div>
      </div>
    </div>
  </Reveal>
</section>

);

export default AboutArea;
