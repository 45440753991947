import React from 'react';
import { navList } from 'components/Navbar/index';
import { Link } from "gatsby"
import './style.css';


const FooterArea = () => (
  <section className="footer-area sec-padding">
    <div className="container">
      <div className="row justify-content-center">
        {/* <div className="col-lg-3 col-sm-6">
          <div className="mt-25px mb-25px">
            <img src="images/logo.png" className="mb-20px" alt="img" />
            <p className="mb-20px">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
            <a className="main-btn btn-3" href="#0">Get Started</a>
          </div>
        </div> */}
        {/* <div className="col-lg-3 col-sm-6">
          <div className="mt-25px mb-25px links">
            <h4 className="mb-20px">Company</h4>
            <h6 className="mb-10px">
              <i className="fa fa-angle-left" /> <a href="#0" className="color-333 color-blue-hvr transition-3">Career</a>
            </h6>
            <h6 className="mb-10px">
              <i className="fa fa-angle-left" /> <a href="#0" className="color-333 color-blue-hvr transition-3">Leadership</a>
            </h6>
            <h6 className="mb-10px">
              <i className="fa fa-angle-left" /> <a href="#0" className="color-333 color-blue-hvr transition-3">Strategy</a>
            </h6>
            <h6 className="mb-10px">
              <i className="fa fa-angle-left" /> <a href="#0" className="color-333 color-blue-hvr transition-3">Services</a>
            </h6>
            <h6 className="mb-10px">
              <i className="fa fa-angle-left" /> <a href="#0" className="color-333 color-blue-hvr transition-3">Blog</a>
            </h6>
          </div>
        </div> */}
        <div className="col-lg-4 col-sm-6">
          <div className="mt-25px mb-25px">
            <h4 className="mb-20px">تواصل معنا</h4>
            <p className="mb-20px">للتواصل معنا عبر الواتس
            <br />
            الأحد - الخميس 8 صباحا - 3 مساءََ
            </p>
            <h6><i className="fa fa-map-marker mr-5px fs-15 color-blue bg-gray radius-50 address text-center" />الكويت - المرقاب - شارع عبد الله المبارك - برج NBT - الدور 16</h6>
            <h6><i className="fa fa-phone mr-5px fs-15 color-blue bg-gray radius-50 address text-center" /> <span style={{ direction: 'ltr' }}> +96560765772</span></h6>
            <h6 className="mb-10px"><i className="fa fa-envelope mr-5px fs-15 color-blue bg-gray radius-50 address text-center" /> galam.kw@gmail.com</h6>
            <div style={{ display: 'flex', alignItems: 'center'}}>
              <a href="https://apps.apple.com/in/app/galam/id1637061929" target="_blank">
                <img style={{ height: 40 }} src="/images/apple-store-badge.svg" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=inc.galam.app" target="_blank">
                <img style={{ height: 55 }} src="/images/google-play-badge.png" />
              </a>
            </div>
            <div className='mb-10px' />
            <a href="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block m-1"><i className="fa fa-facebook" /> </a> 
            <a href="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block m-1"><i className="fa fa-twitter" /> </a> 
            <a href="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block m-1"><i className="fa fa-linkedin" /> </a> 
            <a href="#0" className="social color-blue color-fff-hvr bg-orange-hvr bg-gray text-center radius-50 fs-15 d-inline-block m-1"><i className="fa fa-behance" /> </a> 
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="mt-25px mb-25px links">
            <h4 className="mb-20px">روابط أخرى</h4>
            {navList.map(link => (
              <h6 className="mb-10px">
                <i className="fa fa-angle-left" />{' '}
                  <Link to={link.link} className="color-333 color-blue-hvr transition-3">
                    {link.label}
                  </Link>
              </h6>
            ))}
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default FooterArea;
