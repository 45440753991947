import React from 'react';
import { Reveal } from 'react-reveal';
import './style.css';


const BrandsArea = () =>  {
  return (
    <section className="brands-area sec-padding text-center">
      <Reveal effect="fadeInUp" duration={2000}>
        <div className="container">
          <h1 className="title-h">يساعد نظام قلم  هذه الحضانات  في توفير الوقت وتعزيز الانتاجية</h1>
          {/* <p className="title-p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
          <div className="row">
            {Array.from(Array(17).keys()).map((data, i) => (
              <div className="col-md-2 col-sm-4">
                <div className="mt-25px mb-25px">
                  <div
                    className="nursery-logo"
                    style={{
                      backgroundImage: `url(/images/nursery/logo-${i+1}.png)`
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Reveal>
    </section>
  );
}

export default BrandsArea;
