import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import LoadWrap from '../components/LoadWrapp';

// Blocks
import WelcomeArea from "components/blocks/WelcomeArea";
import AboutArea from "../components/blocks/AboutArea";
import ServicesArea from "../components/blocks/ServicesArea";
import BrandsArea from "../components/blocks/BrandsArea";
import GetStartedArea from "../components/blocks/GetStartedArea";
import VisionArea from "../components/blocks/VisionArea";
import FooterArea from "../components/blocks/FooterArea";


const IndexPage = () => (
  <Layout>
    <SEO title="الرئيسية" keywords={[`الرئيسية`, `قلم`, `الحضانة`]} />
    {/* <LoadWrap /> */}
    <WelcomeArea />
    <AboutArea />
    <ServicesArea />
    <VisionArea />
    <BrandsArea />
    {/* <GetStartedArea /> */}
    <FooterArea />
  </Layout>
)

export default IndexPage
